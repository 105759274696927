@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --foreground-rgb: 255, 255, 255;
  --background: #161618;
  --primary-color: #1e5d44;
  --secondary-color: #34bf8e;
}

body {
  color: rgb(var(--foreground-rgb));
  background: rgb(var(--background));
}

@layer base {
  body {
    @apply selection:bg-[#1e5d44] selection:text-white;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@layer utilities {
  .animate-scroll-right {
    animation: scroll-right 30s linear infinite;
  }

  .animate-scroll-left {
    animation: scroll-left 30s linear infinite;
  }
  
  @keyframes scroll-right {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  @keyframes scroll-left {
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(0);
    }
  }
}


.career-item-enter {
  opacity: 0;
  transform: scale(0.9);
}

.career-item-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.career-item-exit {
  opacity: 1;
}

.career-item-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}